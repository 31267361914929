import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import PropTypes from "prop-types";
import SEO from "../components/seo";
import Container from "../components/container";

const StyledList = styled.ul``;
const StyledListItem = styled.li`
  margin: 0px;
  line-height: 1.8rem;
  & a {
    text-decoration: none;
  }
`;

function SitemapPageLink({ page }) {
  return (
    <StyledListItem>
      <Link to={page.uri}>{page.title}</Link>
      {page.wpChildren?.nodes?.length > 0 && (
        <StyledList>
          {page.wpChildren?.nodes?.map((child) => (
            <SitemapPageLink key={child.uri} page={child} />
          ))}
        </StyledList>
      )}
    </StyledListItem>
  );
}

SitemapPageLink.propTypes = {
  page: PropTypes.object,
};

const Sitemap = ({
  data: {
    allWpPage: { pages },
  },
}) => (
  <Layout>
    <SEO
      title={`Sitemap - Morild InterDesign`}
      description={"Sitemap"}
      breadcrumbs={[{ uri: "/sitemap/", title: "Sitemap" }]}
    />
    <Container maxWidth="md">
      <h1>Sitemap</h1>
      <p>
        <StyledList>
          {pages.map((page) => (
            <SitemapPageLink key={page.uri} page={page} />
          ))}
        </StyledList>
      </p>
    </Container>
  </Layout>
);

Sitemap.propTypes = {
  data: PropTypes.object,
};

export default Sitemap;

export const query = graphql`
  fragment PageData on WpPage {
    uri
    title
  }

  query {
    allWpPage(
      filter: { parentId: { eq: null } }
      sort: { fields: title, order: ASC }
    ) {
      pages: nodes {
        ...PageData
        wpChildren {
          nodes {
            ... on WpPage {
              ...PageData
              wpChildren {
                nodes {
                  ... on WpPage {
                    ...PageData
                    wpChildren {
                      nodes {
                        ... on WpPage {
                          ...PageData
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
